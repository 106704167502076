enum EnumTipoHospedagem {
    SemHospedagem = 1,
    Embutida = 2,
    PagaPeloCliente = 3
}

enum EnumPerfilUsuario {
    Master = "Master",
    Gestor = "Gestor",
    Aluno = "Aluno",
    Convidado = "Convidado"
}

export{
    EnumTipoHospedagem,
    EnumPerfilUsuario
}