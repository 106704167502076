import { AlunoTurma, Empresa, Genero, Profissao, Usuario } from ".";
import { PessoaFisica } from "../shared/Pessoa";

export class Aluno extends PessoaFisica {

    nomeCracha: string = '';
    profissaoId?: number;
    profissao!: Profissao;
    empresaId?: number;
    empresa!: Empresa;
    celularWhatsapp: string = '';
    celular: string = '';
    turmas: AlunoTurma[] = [];
    generoId: number = 0;
    genero!: Genero;
    usuario: Usuario = new Usuario();

    constructor(model?: Aluno) {

        super();

        if (!model)
            return;

        this.nomeCracha = model.nomeCracha;
        this.empresaId = model.empresaId;
        this.empresa = model.empresa;
        this.profissaoId = model.profissaoId;
        this.profissao = model.profissao;
        this.celularWhatsapp = model.celularWhatsapp;
        this.celular = model.celular;
        this.turmas = model.turmas;
        this.generoId = model.generoId;
        this.genero = model.genero;
    }
}