




























import { FormaPagamento } from "@/core/models/geral";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { FormaPagamentoService } from "@/core/services/geral/FormaPagamentoService";
import jiff from 'jiff';

@Component
export default class CadastroCurso extends Vue{
    @Prop() private item!:FormaPagamento;
    @Prop() private value!: string;

    save : boolean = false;
    itemOriginal!:FormaPagamento;
    valid = true;
    dialog =false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    service = new FormaPagamentoService();

    $refs!:{
        formPagamento: HTMLFormElement
    }

    @Watch('item')
    Item(){
        if (this.$refs.formPagamento) {
        this.$refs.formPagamento.resetValidation();
        }
    }

    @Watch('dialog')
    Dialog(){
        if(!this.dialog){
            this.$emit("fechou");
        }
    }
    
    @Watch('value')
    Value (){
        this.dialog = this.value ? true:false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou")
        }   
    }

    Salvar (){
        if(this.$refs.formPagamento.validate()){
            this.save = true;
            
            let patchModel = jiff.diff(this.itemOriginal,this.item,false);
            (this.item.id > 0 ? this.service.Salvar(patchModel,this.item.id):this.service.Salvar(this.item))
            .then(
                res => {
                    this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();
                }
            ).finally(() => {
                this.save =false;
            })
        }
    }
    Close(){
        this.dialog = false;
    }
}
