































import { PageBase } from "@/core/models/shared";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class RelatorioPage extends PageBase {

    @Prop() private titulo!: string;
    @Prop() private paisagem?: boolean;
    @Prop() private exportarExcel?: boolean;

    data: any = Date.now;

    mounted() {

        let root = document.documentElement;

        if(this.paisagem){
            root.style.setProperty("--widthPage", 29.7 + 'cm');
            root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }      
        else{
            root.style.setProperty("--widthPage", 21 + 'cm');
            root.style.setProperty("--min-heightPage", 26 + 'cm');
            this.setPageSize('portrait')
        }
    }

    setPageSize(size: string){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
    }
}
