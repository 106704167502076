export class Profissao implements Shared.IEntity {
    id: number = 0;
    nome: string = '';


    constructor(model?: Profissao) {
        if (!model) {
            return
        }
        this.id = model.id;
        this.nome = model.nome;
    }
}