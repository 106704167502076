import { LocalAcomodacao, Turma } from ".";

export class TurmaAcomodacao {
    turmaId: number = 0;
    turma!: Turma;
    acomodacaoId: number = 0;
    acomodacao!: LocalAcomodacao;
    valor: number = 0;

    constructor(model?: TurmaAcomodacao) {

        if (!model)
            return;
        this.turmaId = model.turmaId;
        this.turma = model.turma;
        this.acomodacaoId = model.acomodacaoId;
        this.acomodacao = model.acomodacao;
        this.valor = model.valor;
    }
}