import { AlunoTurmaParcela, Parametro } from "@/core/models/geral";
import { http } from "../ApiConfig";
import { Service } from "../shared/Service";

export class AlunoTurmaService extends Service {

    constructor() {
        super('alunoTurma')
    }

    private GetFiltro(parametros: string, filter: any){
      if (filter) {
        let paramTemp = '';
        if (filter.turmaId){
          if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `turmaId eq ${filter.turmaId}`;
        }

        if (filter.cursoId){
          if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `turma/cursoId eq ${filter.cursoId}`;
        }

        if (filter.alunoId){
          if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `alunoId eq ${filter.alunoId}`;
        }

        if (filter.situacao != null){
          if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `aprovado eq ${filter.situacao}`;
        }
  
        if (paramTemp) {
  
          if(parametros){
            parametros += '&';
          }
          else {
            parametros += '?';
          }
  
          parametros += `$filter=${paramTemp}`;
        }
      }
      return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string){
  
      let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
  
      parametros = this.GetFiltro(parametros, filter);
  
      return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    public Aprovar(id: number) {
      return http.post(`${this.GetNomeControle()}/${id}/Aprovar`);
    }

    public GeranciarParcelas(id: number, valor: number, qtdParcelas: number, parcelas: AlunoTurmaParcela[], salvar: boolean) {
      return http.put(`${this.GetNomeControle()}/${id}/GeranciarParcelas`, parcelas, {
        params: {
          valor,
          qtdParcelas,
          salvar
        }
      });
    }

    public RelatorioFinanceiro(vencimentoDataInicio: string, vencimentoDataFim: string, pagamentoDataInicio: string, pagamentoDataFim: string, turmaId?: number, alunoId?: number, pago?: boolean){
      return http.get(`${this.GetNomeControle()}/RelatorioFinanceiro`, {
        params:{
          turmaId,
          alunoId,
          pago,
          vencimentoDataInicio,
          vencimentoDataFim,
          pagamentoDataInicio,
          pagamentoDataFim
        }
      });
    }

    public RelatorioFinanceiroAgrupadoAluno(vencimentoDataInicio: string, vencimentoDataFim: string, pagamentoDataInicio: string, pagamentoDataFim: string, turmaId?: number, alunoId?: number, pago?: boolean){
      return http.get(`${this.GetNomeControle()}/RelatorioFinanceiroAgrupadoAluno`, {
        params:{
          turmaId,
          alunoId,
          pago,
          vencimentoDataInicio,
          vencimentoDataFim,
          pagamentoDataInicio,
          pagamentoDataFim
        }
      });
    }

    MontaFiltroFinanceiro(filter: any){
    
        let paramTemp = '';
    
        if (filter.turmaId){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `turmaId eq ${filter.turmaId}`;
        }
    
        if (filter.alunoId){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `alunoId eq ${filter.alunoId}`;
        }

        if (filter.pago){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `parcelas/any(o: o/pago eq ${filter.pago})`;
          }
    
        if (filter.vencimentoDataInicio){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `parcelas/any(o: o/dataVencimento ge ${filter.vencimentoDataInicio})`;
        }
        
        if (filter.vencimentoDataFim){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `parcelas/any(o: o/dataVencimento le ${filter.vencimentoDataFim})`;
        }

        if (filter.pagamentoDataInicio){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `parcelas/any(o: o/dataPagamento ge ${filter.pagamentoDataInicio})`;
        }
        
        if (filter.pagamentoDataFim){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `parcelas/any(o: o/dataPagamento le ${filter.pagamentoDataFim})`;
        }
    
        return paramTemp;  
    }
}