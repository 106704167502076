import { Curso, Usuario } from ".";

export class CursoEquipe {
    usuarioId: number = 0;
    usuario!: Usuario;
    cursoId: number = 0;
    curso!: Curso;

    constructor(model?: CursoEquipe) {
        if (!model)
            return;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.cursoId = model.cursoId;
        this.curso = model.curso;
    }
}