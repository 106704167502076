















import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ConfiguracaoCracha extends PageBase {
    @Prop() private nome!:String;
    @Prop() private nomeCracha!:String;
    @Prop() private isBackground!: boolean;
    @Prop() private background!: any;
    @Prop() private turma!: string;

    fontSize: number = 40

    mounted() {

        if(this.nomeCracha.length <= 15){
            this.fontSize = 34;
        }
        else if(this.nomeCracha.length > 15 && this.nomeCracha.length <= 20){
            this.fontSize = 27;
        }
        else if(this.nomeCracha.length > 20 && this.nomeCracha.length <= 28){
            this.fontSize = 22;
        }
    }

}
