


















































































































































































import { Aluno, AlunoTurma, AlunoTurmaParcela, CashBack, FormaPagamento, LocalAcomodacao, Turma } from "@/core/models/geral";
import { AlunoService, AlunoTurmaService, CashBackService, TurmaService } from "@/core/services/geral";
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import jiff from 'jiff';
import { FormaPagamentoService } from "@/core/services/geral/FormaPagamentoService";
import { PageBase } from "@/core/models/shared";

@Component
export default class CadastroInscricao extends PageBase {
  @Prop() private item!:AlunoTurma;
  @Prop() private value!: string;

  itemOriginal!:AlunoTurma;
  service = new AlunoTurmaService();
  alunoService = new AlunoService();
  alunos: Aluno[]=[];
  turma: Turma = new Turma();
  turmas:any[]=[];
  turmaService = new TurmaService();

  valorTotalOriginal: number = 0;
  
  valid = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  isAlunoLoading: boolean = false;
  isTurmaLoading:boolean = false;
  onSearchTurma: any = null;
  onSearchAlunos:any = null;
  acomodacoes: LocalAcomodacao[] = [];
  stepper: number = 1;

  qtdParcelas: number = 0;
  parcela = new AlunoTurmaParcela()
  parcelas: AlunoTurmaParcela[] = [];

  formaPagamentoService = new FormaPagamentoService();
  formasPagamento: FormaPagamento[] = [];

  tudoPago: boolean = this.TudoPago();

  dialogValorInconforme: boolean = false;

  headerParcelas: any[] = [
    { text: "Valor", value: "valor", sortable: false },
    { text: "Forma Pagamento", value: "formaPagamentoId", sortable: false },
    { text: "Data Vencimento", value: "dataVencimento", sortable: false},
    { text: "Data Pagamento", value: "dataPagamento", sortable: false},
    { text: "Pago", value: "actions", sortable: false }
  ];

  cashbackService = new CashBackService();
  cashBacks: CashBack[] = [];
  cashBackSelect: CashBack[] = [];
  dialogCashBack: boolean = false;
  valorCashBack: number = 0;
  headerCashBack: any[] = [
    { text: "Valor", value: "valor" },
    { text: "Data", value: "dataHoraCriacao"},
    { text: "Data Validade", value: "dataHoraValidade"},
  ];

  totalMostrar: number = 0;

  $refs!:{
    form: HTMLFormElement
    formParcela: HTMLFormElement
  }

  @Watch('dialog')
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    } 

    if(this.item.id > 0 && this.item.acomodacaoId){
      this.acomodacoes.push(this.item.acomodacao);
    }  

    console.log(this.item.parcelas);
    this.parcelas = this.item.parcelas;
    this.item.parcelas.length < 1 ? this.qtdParcelas = 1 : this.qtdParcelas = this.item.parcelas.length;
    this.valorCashBack = this.item.valorCashBack;

    this.valorTotalOriginal = this.item.valorTotal;
  }

  @Watch('value')
  Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  // @Watch('onSearchTurma')
  // SearchTurma (val:string){
  //   if(this.item.turmaId) 
  //     return;
  //   if(this.isTurmaLoading)
  //     return;
  //   if(!val)
  //     return;

  //   this.isTurmaLoading = true
  //   this.turmaService.AutoComplete(val, "Curso, TipoHospedagem, Alunos").then(
  //     res =>{
  //       this.turmas = res.data;
  //     },
  //     err => this.$swal('Aviso',err.response.data,'error')
  //   ).finally(() =>{
  //     this.isTurmaLoading = false;
  //   })
  // }

  @Watch('qtdParcelas')
  VerificarParcela (){

    if(this.qtdParcelas < 1)
      this.qtdParcelas = 1;
    else if(this.qtdParcelas > 18)
      this.qtdParcelas = 18;

    if(this.item.parcelas.length != this.qtdParcelas){
      if(this.qtdParcelas == 1){
        this.item.valorTotal = this.item.turma.valorAVista - this.valorCashBack;
      }
      else if(this.qtdParcelas > 1 && this.qtdParcelas <= 6){
        this.item.valorTotal = this.item.turma.valorParceladoAte6Vezes - this.valorCashBack;
      }
      else if(this.qtdParcelas > 6 && this.qtdParcelas <= 12){
        this.item.valorTotal = this.item.turma.valorParceladoAte12Vezes - this.valorCashBack;
      }
      else if(this.qtdParcelas > 12 && this.qtdParcelas <= 18){
        this.item.valorTotal = this.item.turma.valorParceladoAte18Vezes - this.valorCashBack;
      }
    }
  }

  @Watch('item.turmaId')
  WatchTurma(val: any){
    if(val == 0 || this.item.id > 0)
      return;
    this.item.turma = this.turmas.find(x => x.id == this.item.turmaId);
    this.item.valorTotal = this.item.turma.valorAVista;
    this.item.turma.acomodacoes.forEach(x => {
      this.acomodacoes.push(x.acomodacao);
    });    
  }

  @Watch('item.alunoId')
  WatchCashback(val: any){
    
    if(!(val > 0))
      return;

    this.cashbackService.CashBackDisponivel(this.item.alunoId).then(
      res => {
        this.cashBacks = res.data;
      }
    )
  }

  @Watch('item.valorTotal')
  @Watch('item.valorCashBack')
  WatchValorTotal(){
    this.totalMostrar = this.item.valorTotal + this.valorCashBack;
  }

  @Watch('valorCashBack')
  WatchEntradaCashBack(){
    this.VerificarParcela();
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {

    this.formaPagamentoService.ListarTudo().then(
      res =>{
        this.formasPagamento = res.data.items;
        
        const formaPagamento = new FormaPagamento();
        formaPagamento.nome = "---SELECIONE---";
        this.formasPagamento.unshift(formaPagamento);
      }
    )

    this.turmaService.ListarAbertas().then(
      res => {
        this.turmas = res.data.items;
      }
    )

    this.alunoService.JustGestor().then(
      res => {
        this.alunos = res.data;
      }
    )

  }

  TudoPago(){

    if(this.item.parcelas.length == 0)
      return false;

    this.item.parcelas.forEach(x => {
      if(!x.pago){
        return false;
      }
    });

    return true;
  }

  //SERÁ USADO QUANDO A INSCRIÇÃO AINDA N FOI FEITA
  GerarParcelas(){
    var valorCada = parseFloat((this.item.valorTotal / this.qtdParcelas).toFixed(2));
    var hoje = new Date();
    var fatorCorrecao = 1;
    var valorTeste = 0;

    this.item.parcelas.splice(0, this.item.parcelas.length);
    this.parcelas.splice(0, this.parcelas.length);
    
    for(var i = 0; i < this.qtdParcelas; i++){
      this.parcela = new AlunoTurmaParcela();
      this.parcela.valor = parseFloat(valorCada.toFixed(2));
      valorTeste += this.parcela.valor;
      this.parcela.usuarioId = this.app.usuarioId;
      this.parcela.dataVencimento = new Date(new Date(hoje).setMonth(hoje.getMonth() + fatorCorrecao)).toYYYYMMDD();
      this.parcela.pago = false;
      this.item.parcelas.push(this.parcela);
      fatorCorrecao++;
    }

    if(valorTeste != this.item.valorTotal){
      let testeDiferenca = this.item.valorTotal - valorTeste;
      this.item.parcelas[0].valor = parseFloat((this.item.parcelas[0].valor + testeDiferenca).toFixed(2));
    }

    this.parcela = new AlunoTurmaParcela();

  }

  VisualizarParcelas(){
    this.service.GeranciarParcelas(this.item.id, this.item.valorTotal, this.qtdParcelas, this.item.parcelas, false).then(
      res => {
        this.parcelas = [];
        this.parcelas = res.data;
      },
      err => {
        this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
      }
    )
  }

  AtualizarValores(index){

    let totalFixado = 0;
    let quantidadeRestante = this.parcelas.length - (index + 1);

    for (let i = 0; i <= index; i++) {
      totalFixado += this.parcelas[i].valor;
    }

    let valorRestante = (this.item.valorTotal - totalFixado) / quantidadeRestante;

    for (let i = index+1; i < this.parcelas.length; i++) {
      this.parcelas[i].valor = valorRestante;
    }

  }

  AtualizarFormaPagamento(formaPagamentoId, index){

    for (let i = index+1; i < this.parcelas.length; i++) {
      this.parcelas[i].formaPagamentoId = formaPagamentoId;
    }

  }

  VerificaDataPagamento(index){

    if (this.parcelas[index].pago && !this.parcelas[index].dataPagamento){
      this.$swal("Aviso", "Informe a data de pagamento!", "warning");
      return false;
    }

    return true;
    
  }

  RemoverDataPagamento(index){
    if(!this.parcelas[index].pago){
      this.itemOriginal.parcelas[index].dataPagamento = '';
      this.parcelas[index].dataPagamento = '';
    }
  }

  ValidarCondicoesInscricao(){
    let valorTeste = 0;
    let semFormaPagamento = false;

    if(this.item.id == 0){
      this.parcelas = this.item.parcelas;
    }

    this.parcelas.forEach(x => {
      valorTeste += x.valor;
      valorTeste = parseFloat(valorTeste.toFixed(2))
      if(x.formaPagamentoId == 0){
        semFormaPagamento = true;
      }
    });

    if( ((this.qtdParcelas == 1 && this.item.valorTotal != this.item.turma.valorAVista - this.valorCashBack) || 
          (this.qtdParcelas > 1 && this.qtdParcelas <= 6 && this.item.valorTotal != this.item.turma.valorParceladoAte6Vezes - this.valorCashBack) ||
            (this.qtdParcelas > 6 && this.qtdParcelas <= 12 && this.item.valorTotal != this.item.turma.valorParceladoAte12Vezes - this.valorCashBack)) && this.item.solicitacao.justificativa.length == 0 )
    {
      this.dialogValorInconforme = true;
      return false;
    }
    else if (valorTeste != this.item.valorTotal){
      this.$swal("Aviso", "A soma das parcelas não condiz com o valor total da inscrição!", "warning");
      return false;
    }
    else if (semFormaPagamento){
      this.$swal("Aviso", "Uma ou mais parcelas não possui forma de pagamento definida!", "warning");
      return false;
    }

    return true;
  }

  AdicionarCashBack(){
    let vm = this;
    this.valorCashBack = 0;
    this.valorCashBack += Object.keys(this.cashBackSelect).reduce(function(previous, key) {
      return previous + vm.cashBackSelect[key].valor;
    }, 0);

    this.item.cashBacks = this.cashBackSelect;
    this.dialogCashBack = false;
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      if(this.ValidarCondicoesInscricao()){
        this.service.Salvar(this.item).then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          },
          err => {
            this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
          }
        );
      }
    }
  }

  Editar(){

    if(this.ValidarCondicoesInscricao()){
      this.service.Put(this.item).then(
        res => {
          this.service.GeranciarParcelas(this.item.id, this.item.valorTotal, this.qtdParcelas, this.parcelas, true).then(
            res => {
              this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");      
              this.$emit("salvou");
              this.Close();
            },
            err =>{
              this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
            }
          )
        },
        err => {
          this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
        }
      )
    }
    
  }
  
  Close() {
    this.dialog = false;
    this.stepper = 1;
  }
}
