import { FormaPagamento } from "./FormaPagamento";

export class AlunoTurmaParcela implements Shared.IEntity {
    id: number = 0;
    formaPagamentoId: number = 0;
    formaPagamento!: FormaPagamento;
    dataVencimento: string = '';
    valor: number = 0;
    dataPagamento: string = '';
    pago: boolean = false;
    usuarioId: number = 0;

    constructor(model?: AlunoTurmaParcela) {

        if (!model)
            return;

        this.id = model.id;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.dataVencimento = model.dataVencimento.toDateYYYYMMDD();
        this.valor = model.valor;
        this.dataPagamento = model.dataPagamento? model.dataPagamento.toDateYYYYMMDD() : '';
        this.pago = model.pago;
        this.usuarioId = model.usuarioId;
    }
}