import { LocalAcomodacao } from ".";
import { Endereco } from "../shared";

export class Local implements Shared.IEntity{

    id: number = 0;
    nome: string = '';
    email: string = '';
    telefone: string = '';
    endereco: Endereco = new Endereco();
    acomodacoes: LocalAcomodacao[] = [];
    ativo: boolean = true;

    constructor(model?: Local){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.endereco = model.endereco;
        this.acomodacoes = model.acomodacoes;
        this.ativo = model.ativo;
    }
}    