





















































































































import { Curso, CursoEquipe, Estado, Usuario } from "@/core/models/geral";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { CursoService } from "@/core/services/geral";
import { ArquivoService, EnderecoService } from "@/core/services/shared";
import { UsuarioService } from "@/core/services/geral/UsuarioService";

@Component
export default class CadastroCurso extends Vue {
    @Prop() private item!:Curso;
    @Prop() private value!: string;

    save : boolean = false;
    itemOriginal!:Curso;
    valid = true;
    validInstrutor = true;
    dialog =false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    service = new CursoService();
    estadoId:number=0;
    estado:Estado = new Estado();
    estados: any[] = [];
    usuarioId:number=0;
    usuarios: Usuario[] = [];
    usuarioService = new UsuarioService();
    enderecoService = new EnderecoService();
    tabActive: any = null;    
    cursoEquipe: CursoEquipe = new CursoEquipe ();
    cracha: any = null;

    isUsuarioLoading: boolean =false;
    onSearchUsuario:any =null;
    $refs!:{
        formCurso: HTMLFormElement
        formInstrutor: HTMLFormElement
    }

    headerInstrutores: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Head Trainer", value: "usuario.complementar.nome" },
    ];

    @Watch('item')
    Item(){
        if (this.$refs.formCurso) {
            this.$refs.formCurso.resetValidation();
        }
        if (this.$refs.formInstrutor) {
            this.$refs.formInstrutor.resetValidation();
        }

        if(this.item.id > 0){
            this.service.ObterCracha(this.item.id).then(
                res => {
                    this.item.cracha = res.data;
                },
                err => {
                    this.$swal('Aviso', err.message, 'error')
                }
            )
        }

    }

    @Watch('dialog')
    Dialog(){
        if(!this.dialog){
            this.$emit("fechou");
        }
    }
    
    @Watch('value')
    Value (){
        this.dialog = this.value ? true:false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    @Watch('onSearchUsuario')
    SearchUsuario (val:string){
        if(this.usuarioId) return;
        if(this.isUsuarioLoading)return;
        if(!val)return;
        this.isUsuarioLoading = true;
        this.usuarioService.AutoComplete(val).then(
            res =>{
                this.usuarios = res.data;
            },
            err => this.$swal('Aviso',err.response.data,'error')
        ).finally(()=>{
            this.isUsuarioLoading=false;
        })
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou")
        }   
    }

    mounted() {
        this.enderecoService.ObterEstados()
        .then(
            res => {
                this.estados = res.data.items;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        );

        this.usuarioService.ListarGestores()
        .then(
            res =>{
                this.usuarios = res.data.items;
            },
            err =>{
                this.$swal('Aviso',err.message,'error')
            }
        )
    }

    async LoadImage(){

        if (!this.cracha)
            return;

        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.cracha);
        this.item.cracha = dados.replace(/^[^,]*,/, "");
    }

    RemoveImage(){
        this.item.cracha = null;
        this.cracha = null;
    }

    AdicionarUsuarioInstrutor(){
        if(this.$refs.formInstrutor.validate()){
            if(this.item.equipe.find(x => x.usuarioId == this.cursoEquipe.usuarioId )){
                this.$swal("Aviso","Esse usuário já se encontra adicionado!","warning")
            }
            else{
                this.cursoEquipe.cursoId = this.item.id;
                this.cursoEquipe.usuario = this.usuarios.find(x => x.id == this.cursoEquipe.usuarioId)!;
                this.item.equipe.push(this.cursoEquipe);
            }
            this.$refs.formInstrutor.resetValidation();
            this.cursoEquipe = new CursoEquipe();
        }
    }

    ExcluirUsuario(equipe:CursoEquipe){
        this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
            const index = this.item.equipe.indexOf(equipe);
            this.item.equipe.splice(index,1);

            return true;
            },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    itemStates(item: any){
        if(item.excluido)
        return 'itemExcluido'
        else if(item.atualizado && !item.excluido)
        return 'itemAlterado'
        else
        return ''
    }

    Salvar (){
        if(this.$refs.formCurso.validate()){
            this.save = true;
            
            let patchModel = jiff.diff(this.itemOriginal,this.item,false);

            (this.item.id > 0 ? this.service.Salvar(patchModel,this.item.id) : this.service.Salvar(this.item))
            .then(
                res => {
                    this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200? "success" : "warning");
                    this.$emit("salvou");
                    this.Close();
                }
            ).finally(() => {
                this.save =false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
