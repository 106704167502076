export class Parametro implements Shared.IEntity {
    
    id: number = 0;
    certificado: any = null;

    constructor(model?: Parametro) {

        if (!model) {
            return;
        }

        this.id = model.id;
        this.certificado = model.certificado;
    }
}