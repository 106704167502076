import { http, httpHeader } from "../ApiConfig";
import { Service } from "../shared/Service";

export class CursoService extends Service {

  constructor() {
    super('curso');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    })
  }

  public JustHeadTrainer() {
    return http.get(`${this.GetNomeControle()}/JustHeadTrainer`);
  }

  public ObterCracha(id: number) {
    return http.get(`${this.GetNomeControle()}/${id}/Cracha`)
  }
}