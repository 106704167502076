import { CursoEquipe, PerfilUsuario } from './';
import { Contato, Endereco } from '../shared';

export class Usuario implements Shared.IEntity {
    id: number = 0;
    login: string = '';
    senha: string = '';
    cpf: string = '';
    complementar: Contato = new Contato();
    nomeCracha: string = '';
    nascimento: string = '';
    perfilId: number = 1;
    perfil!: PerfilUsuario;
    ativo: boolean = true;
    parentId?: number;
    parent!: Usuario;
    equipe: Usuario[] = [];
    endereco: Endereco = new Endereco();
    percentualComissao: number = 0;
    assinatura: any = null;
    foto: any = null;
    temaEscuro: boolean = false;
    primeiraQualificacao: string = '';
    segundaQualificacao: string = '';

    gestorId?: number;
    gestor!: Usuario;
    indicadorId?: number;
    indicador!: Usuario;
    hasAluno: boolean = false;

    constructor(model?: Usuario) {
        if (!model)
            return;

        this.id = model.id
        this.login = model.login
        this.senha = model.senha
        this.complementar = model.complementar;
        this.nomeCracha = model.nomeCracha;
        this.nascimento = model.nascimento;
        this.perfilId = model.perfilId
        this.perfil = model.perfil
        this.ativo = model.ativo
        this.foto = model.foto
        this.temaEscuro = model.temaEscuro
        this.parentId = model.parentId;
        this.parent = model.parent;
        this.equipe = model.equipe;
        this.percentualComissao = model.percentualComissao;
        this.assinatura = model.assinatura;
        this.endereco = model.endereco;
        this.primeiraQualificacao = model.primeiraQualificacao;
        this.segundaQualificacao = model.segundaQualificacao;

        this.indicadorId = model.indicadorId;
        this.indicador = model.indicador;
        this.gestorId = model.gestorId;
        this.gestor = model.gestor;
        this.hasAluno = model.hasAluno;
    }
}