import { Endereco } from './Endereco';

export class Pessoa implements Shared.IEntity {

    id: number = 0;
    nome: string = '';
    dataCriacao: string = '';
    isJuridica: boolean = false;
    fisica?: PessoaFisica;
    juridica?: PessoaJuridica;
    endereco: Endereco = new Endereco();
    telefone: string = '';
    email: string = '';
    ativo: boolean = true;

    constructor(model?: Pessoa) {

        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.dataCriacao = model.dataCriacao;
        this.isJuridica = model.isJuridica;
        this.fisica = model.fisica;
        this.juridica = model.juridica;
        this.endereco = model.endereco;
        this.telefone = model.telefone;
        this.email = model.email;
        this.ativo = model.ativo;
    }

}

export class PessoaJuridica extends Pessoa {

    cnpj: string = '';
    nomeFantasia: string = '';
    inscricaoEstadual: string = '';
    inscricaoMunicipal: string = '';

    constructor(model?: PessoaJuridica) {

        super();

        if (!model)
            return;

        this.cnpj = model.cnpj;
        this.nomeFantasia = model.nomeFantasia;
        this.inscricaoEstadual = model.inscricaoEstadual;
        this.inscricaoMunicipal = model.inscricaoMunicipal;
    }
}

export class PessoaFisica extends Pessoa {

    cpf: string = '';
    identidade: string = '';
    nascimento: string = '';
    isMasculino: boolean = false;

    constructor(model?: PessoaFisica) {

        super();

        if (!model)
            return;

        this.cpf = model.cpf;
        this.identidade = model.identidade;
        this.nascimento = model.nascimento ? model.nascimento.toDateYYYYMMDD() : '';
        this.isMasculino = model.isMasculino;
    }
}