

































































































































































































































































































import Component from "vue-class-component";
import { Curso, Local, LocalAcomodacao, TipoHospedagem, Turma, TurmaAcomodacao, TurmaInstrutor, TurmaSecretario, Usuario } from "@/core/models/geral";
import { Vue,Prop, Watch } from "vue-property-decorator";
import { CashBackService, CursoService, LocalService, TipoHospedagemService, TurmaService, UsuarioService } from "@/core/services/geral";
import { EnumPerfilUsuario, EnumTipoHospedagem } from "@/core/models/shared/Enumeradores"
import jiff from 'jiff';
import { TurmaTreinador } from "@/core/models/geral/TurmaTreinador";
import { PageBase } from "@/core/models/shared";


@Component
export default class CadastroTurma extends PageBase {
    @Prop() private item!:Turma;
    @Prop() private value!:string;

    enumSituacaoOrdemServico = EnumTipoHospedagem;

    save : boolean = false;
    itemOriginal!:Turma;
    service = new TurmaService();
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    tabActive: any = null;
    
    valid = true;
    validAcomodacoes = true;
    validAlunos = true;
    validInstrutores = true;
    validSecretarios = true;
    validTreinadores = true;

    locais:Local []=[];    
    localId:number=0;
    localService = new LocalService();
    isLocalLoading :boolean= false;
    onSearchLocal: any = null;

    cursos: Curso []=[];
    cursoService  = new CursoService();
    isCursoLoading:boolean = false;

    tipoHospedagemId: number = 0;
    tipoHospedagemService = new TipoHospedagemService();
    istipoHospedagemLoading:boolean=false;
    onSearchTipoHospedagem: any = null;
    tipoHospedagens:TipoHospedagem []=[];

    acomodacoes: LocalAcomodacao[] = [];
    turmaAcomodacao: TurmaAcomodacao = new TurmaAcomodacao ();

    equipe: Usuario[] = [];

    turmaInstrutor: TurmaInstrutor = new TurmaInstrutor();
    turmaSecretario: TurmaSecretario = new TurmaSecretario();
    turmaTreinador: TurmaTreinador = new TurmaTreinador();

    anjos: Usuario[] = [];
    usuarios: Usuario[] = [];
    usuarioService = new UsuarioService();

    masterEnum = EnumPerfilUsuario.Master;

    selectAlunos: any = [];

    headerAcomodacoes: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Acomodacoes", value: "acomodacao.nome" },
        { text: "Valor", value: "valor" },
    ];
    headerInstrutores: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Nome", value: "usuario.complementar.nome" },
    ];
    headerSecretarios:any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Nome", value: "usuario.complementar.nome" },
    ]
    headerTreinadores:any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Nome", value: "usuario.complementar.nome" },
    ]
    headersAlunos: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Validado', value:'isValidada'},
        { text: 'Nome', value: 'aluno.nome' },
        { text: 'Data/Hora Criação', value: 'dataHora', type:'date' },
        { text: 'Valor', value: 'valorTotal', type:'number' },
        { text: 'Aprovado', value: 'aprovado', type:'bool' },
    ];

    $refs!: {
        formTurma: HTMLFormElement;
        formAcomodacao: HTMLFormElement;
        formAlunos: HTMLFormElement;
        formInstrutores: HTMLFormElement;
        formSecretarios: HTMLFormElement;
        formTreinadores: HTMLFormElement;
    }

    @Watch('item')
    Item(){
        if(this.$refs.formTurma){
            this.$refs.formTurma.resetValidation();
        }
    }

    @Watch('value')
    Value (){
        this.dialog = this.value ? true:false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    @Watch('dialog')
    Dialog(){
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    @Watch('item.cursoId')
    WatchCurso(val: number){

        if(val == null || val == 0)
            return;

        this.cursoService.ObterPorId(val,"Equipe.Usuario").then(
            res => {
                this.equipe = [];
                res.data.equipe.forEach(x => {
                    this.equipe.push(x.usuario);
                });                
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        )
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    // popularLista(){
    //     this.lista = [];
    //     this.selectLista = [];

    //     if(this.alunos){
    //         this.turma.alunos.forEach( aluno => {
    //             this.lista.push({
    //                 id: aluno.alunoId,
    //                 nome: aluno.aluno.nome,
    //                 tipo: "Aluno"
    //             })
    //         })
    //     }
    // }

    mounted() {
        this.localService.ListarTudo().then(
            res => {
                this.locais = res.data.items;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        );

        this.cursoService.JustHeadTrainer().then(
            res => {
                this.cursos = res.data;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        );

        this.tipoHospedagemService.ListarTudo().then(
            res => {
                this.tipoHospedagens = res.data.items;
            },
            err =>{
                this.$swal('Aviso',err.message,'error')
            }
        )

        this.usuarioService.ListarTudo().then(
            res => {
                this.anjos = res.data.items;
            }
        )

        this.usuarioService.ListarGestores().then(
            res => {
                this.usuarios = res.data.items;
            }
        )

        // this.acomodacaoService.ListarTudo().then(
        //     res => {
        //         this.acomodacoes = res.data.items;
        //     },
        //     err =>{
        //         this.$swal('Aviso',err.message,'error')
        //     }
        // )
    }

    AdicionarAcomodacaoTurma(){
        if(this.$refs.formAcomodacao.validate()){
            if(this.item.acomodacoes.find(x => x.acomodacaoId == this.turmaAcomodacao.acomodacaoId )){
                this.$swal("Aviso","Esse usuário já se encontra adicionado!","warning")
            }
            else{
                this.turmaAcomodacao.turmaId = this.item.id;
                this.turmaAcomodacao.acomodacao = this.acomodacoes.find(x => x.id == this.turmaAcomodacao.acomodacaoId)!;
                this.item.acomodacoes.push(this.turmaAcomodacao)
            }
            if(this.$refs.formAcomodacao){
                this.$refs.formAcomodacao.resetValidation();
            }
            this.turmaAcomodacao = new TurmaAcomodacao();
        }
    }

    AdicionarInstrutor(){
        if(this.$refs.formInstrutores.validate()){
            if(this.item.instrutores.find(x => x.usuarioId == this.turmaInstrutor.usuarioId)){
                this.$swal("Aviso","Esse insstrutor já se encontra adicionado!","warning")
            }
            else{
                this.turmaInstrutor.turmaId = this.item.id;
                this.turmaInstrutor.usuario = this.equipe.find(x => x.id == this.turmaInstrutor.usuarioId)!;
                this.item.instrutores.push(this.turmaInstrutor);
            }
            if(this.$refs.formInstrutores){
                this.$refs.formInstrutores.resetValidation();
            }
            this.turmaInstrutor = new TurmaInstrutor();
        }
    }

    AdicionarTreinadores(){
        if(this.$refs.formTreinadores.validate()){
            if(this.item.treinadores.find(x => x.usuarioId == this.turmaTreinador.usuarioId)){
                this.$swal("Aviso","Esse insstrutor já se encontra adicionado!","warning")
            }
            else{
                this.turmaTreinador.turmaId = this.item.id;
                this.turmaTreinador.usuario = this.usuarios.find(x => x.id == this.turmaTreinador.usuarioId)!;
                this.item.treinadores.push(this.turmaTreinador);
            }
            if(this.$refs.formTreinadores){
                this.$refs.formTreinadores.resetValidation();
            }
            this.turmaTreinador = new TurmaTreinador();
        }
    }

    AdicionarSecretarios(){
        if(this.$refs.formSecretarios.validate()){
            if(this.item.secretarios.find(x => x.usuarioId == this.turmaSecretario.usuarioId)){
                this.$swal("Aviso","Esse insstrutor já se encontra adicionado!","warning")
            }
            else{
                this.turmaSecretario.turmaId = this.item.id;
                this.turmaSecretario.usuario = this.usuarios.find(x => x.id == this.turmaSecretario.usuarioId)!;
                this.item.secretarios.push(this.turmaSecretario);
            }
            if(this.$refs.formSecretarios){
                this.$refs.formSecretarios.resetValidation();
            }
            this.turmaSecretario = new TurmaInstrutor();
        }
    }

    ExcluirAcomodacao(acomodacao:TurmaAcomodacao){
        this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
            const index = this.item.acomodacoes.indexOf(acomodacao);
            this.item.acomodacoes.splice(index,1);

            return true;
            },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    ExcluirInstrutor(instrutor: TurmaInstrutor){
        this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
            const index = this.item.instrutores.indexOf(instrutor);
            this.item.instrutores.splice(index,1);

            return true;
            },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
    }
    ExcluirTreinador(treinador: TurmaTreinador){
        this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
            const index = this.item.treinadores.indexOf(treinador);
            this.item.treinadores.splice(index,1);

            return true;
            },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    ExcluirSecretarios(secretario: TurmaSecretario){
        this.$swal({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            
            const index = this.item.instrutores.indexOf(secretario);
            this.item.secretarios.splice(index,1);

            return true;
            },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    @Watch('onSearchLocal')
    SearchLocal (val:string){
        if(this.item.localId) return;
        if(this.isLocalLoading) return;
        if (!val)return;
        this.isLocalLoading = true
        this.localService.AutoComplete(val)
          .then(
              res => {
                  this.locais = res.data;
              },
              err => this.$swal('Aviso',err.response.data, 'error')
        ).finally(() => {
                this.isLocalLoading = false;
        })
    }
    @Watch('onSearchTipoHospedagem')
    SearchHospedagem (val:string){
        if(this.item.tipoHospedagemId) return;
        if(this.istipoHospedagemLoading) return;
        if (!val)return;
        this.istipoHospedagemLoading = true
        this.tipoHospedagemService.AutoComplete(val)
          .then(
              res => {
                  this.locais = res.data;
              },
              err => this.$swal('Aviso',err.response.data, 'error')
        ).finally(() => {
                this.istipoHospedagemLoading = false;
        })
    }

    Salvar (){
        if (this.$refs.formTurma.validate()){
            this.save = true;

            let patchModel = jiff.diff(this.itemOriginal,this.item,false);

            (this.item.id > 0 ? this.service.Salvar(patchModel,this.item.id): this.service.Salvar(this.item)).then(
                res => {
                    if(this.item.isValidada && !this.item.ativo){
                        var turmaId = res.data.id ? res.data.id : this.item.id;
                        new CashBackService().GerarCashBack(turmaId).then(
                            res => {
                                this.$swal.fire({
                                    html: res.data,
                                    icon: 'success',
                                    title: 'Aviso'
                                });
                            },
                            err => {
                                this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
                            }
                        )
                    }
                    else{
                        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");                        
                    }
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
                }
            ).finally(() => {
                this.save = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
