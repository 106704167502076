import { Turma, Usuario } from ".";
export class TurmaTreinador {
    usuarioId: number = 0;
    usuario!: Usuario;
    turmaId: number = 0;
    turma!: Turma;

    constructor(model?: TurmaTreinador) {

        if (!model)
            return;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.turmaId = model.turmaId;
        this.turma = model.turma;
    }
}