import { Usuario } from "../geral/Usuario";

export class Aprovacao {

    dataHora?: string;
    aprovadorId?: number;
    aprovador!: Usuario;

    constructor(model?: Aprovacao) {

        if (!model)
            return;

        this.dataHora = model.dataHora ? model.dataHora.toDateYYYYMMDD() : '';
        this.aprovadorId = model.aprovadorId;
        this.aprovador = model.aprovador;
    }
}