import { PessoaJuridica } from "../shared/Pessoa";

export class Empresa extends PessoaJuridica {
    nomeFinanceiro: string = ''
    emailFinanceiro: string = ""
    telefoneFinanceiro: string = ""
    nomeComercial: string = ''
    emailComercial: string = ""
    telefoneComercial: string = ""

    constructor(model?: Empresa) {
        super();
        if (!model)
            return;
        this.nomeFinanceiro = model.nomeFinanceiro;
        this.emailFinanceiro = model.emailFinanceiro;
        this.telefoneFinanceiro = model.telefoneFinanceiro;
        this.nomeComercial = model.nomeComercial;
        this.emailComercial = model.emailComercial;
        this.telefoneComercial = model.telefoneComercial;
    }
}