import { Aluno, AlunoTurmaParcela, LocalAcomodacao, Turma, Usuario } from ".";
import { Aprovacao } from "../shared/Aprovacao";
import { Solicitacao } from "../shared/Solicitacao";
import { CashBack } from "./CashBack";

export class AlunoTurma implements Shared.IEntity {

    id: number = 0;
    alunoId: number = 0;
    aluno!: Aluno;
    turmaId: number = 0;
    turma!: Turma
    acomodacaoId?: number;
    acomodacao!: LocalAcomodacao;
    valorTotal: number = 0;
    usuarioId: number = 0;
    usuario!: Usuario;
    aprovado: boolean = false;
    aprovacao: Aprovacao = new Aprovacao();
    isValidada: boolean = false;
    solicitacao: Solicitacao = new Solicitacao();
    dataHora: string = '';
    parcelas: AlunoTurmaParcela[] = [];
    cashBacks: CashBack[] = [];
    valorCashBack: number = 0;

    constructor(model?: AlunoTurma) {

        if (!model)
            return;

        this.id = model.id;
        this.alunoId = model.alunoId;
        this.aluno = model.aluno;
        this.turmaId = model.turmaId;
        this.turma = model.turma;
        this.acomodacaoId = model.acomodacaoId;
        this.acomodacao = model.acomodacao;
        this.valorTotal = model.valorTotal;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.aprovado = model.aprovado;
        this.isValidada = model.isValidada;
        this.dataHora = model.dataHora ? model.dataHora.toDateYYYYMMDD() : '';
        this.parcelas = model.parcelas;
        this.cashBacks = model.cashBacks;
        this.valorCashBack = model.valorCashBack;
    }
}



