import { http, httpHeader } from "../ApiConfig";
import { Service } from "../shared/Service";

export class AlunoService extends Service {

  constructor() {
    super('aluno');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    })
  }

  public JustGestor() {
    return http.get(`${this.GetNomeControle()}/JustGestor`);
  }

  public GetByGestorIndicador(usuarioId: number, include: string) {
    return http.get(`${this.GetNomeControle()}?$orderBy=Nome & $filter=usuario/indicadorId eq ${usuarioId} or usuario/gestorId eq ${usuarioId}`, {
      headers: this.GetHeader(include).headers
    })
  }

  public Aniversariantes(inicio: string, fim: string) {
    return http.get(`${this.GetNomeControle()}/Aniversariantes`, {
      params:{
        inicio,
        fim
      }
    });
  }
}