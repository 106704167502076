export class FormaPagamento implements Shared.IEntity {
    id: number = 0;
    nome: string = '';
    ativo: boolean = true;

    constructor(model?: FormaPagamento) {
        if (!model)
            return

        this.id = model.id;
        this.nome = model.nome;
        this.ativo = model.ativo;
    }
}