


















































































































































































































import { Empresa, Estado } from "@/core/models/geral";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { EmpresaService } from "@/core/services/geral";
import { EnderecoService } from "@/core/services/shared";

@Component
export default class CadastroEmpresa extends Vue {
    @Prop() private item!: Empresa;
    @Prop() private value!: string;

    save: boolean = false;
    itemOriginal!: Empresa;
    valid = true;
    dialog = false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    empresaId: number = 0;
    empresa: Empresa = new Empresa();
    service = new EmpresaService();
    empresas: any[] = [];

    enderecoService = new EnderecoService();
    estadoId: number = 0;
    estado: Estado = new Estado();
    estados: any[] = []
    municipios: any[] = []

    mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    mskCep = process.env.VUE_APP_MSK_CEP;


    $refs!: {
        form: HTMLFormElement
    }

    headerEmpresa: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Nome", value: "nome" },
    ];

    @Watch('item')
    Item() {
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('dialog')
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }
    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }
    @Watch("item.endereco.municipioId")
    onMunicipioIdChanged(value: number) {
        if (this.item.endereco.municipioId == 0)
            return;
        this.estadoId = this.item.endereco.municipio.estadoId;
    }

    @Watch('value')
    Value() {
        this.dialog = this.value ? true : false;

        if (this.dialog) {
            this.itemOriginal = jiff.clone(this.item);
        }
    }

    beforeUpdate() {
        if (!this.dialog) {
            this.$emit("fechou")
        }
    }
    mounted() {
        this.service.ListarTudo()
            .then(
                res => {
                    this.empresas = res.data.items;
                },
                err => {
                    this.$swal('Aviso', err.message, 'error');
                }
            );
        this.enderecoService.ObterEstados()
            .then(
                res => {
                    this.estados = res.data.items;
                },
                err => {
                    this.$swal('Aviso', err.message, 'error')
                }
            )
    }

    CarregarMunicipio(estadoId: number) {
        this.enderecoService.ObterMunicipios(estadoId)
            .then(
                res => {
                    this.municipios = res.data.items;
                },
                err => this.$swal('Aviso', err.message, 'error')
            );
    }

    ObterEndereco(cep: string) {
        this.enderecoService.ObterPorCep(cep).then(
            res => {
                const endereco = res.data;
                if (endereco) {
                    this.item.endereco.logradouro = endereco.logradouro;
                    this.item.endereco.bairro = endereco.bairro;
                    this.estadoId = endereco.estadoId;
                    this.item.endereco.municipioId = endereco.municipioId;
                }
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        ).finally(() => {
            this.save = false;
        })
    }
    Salvar() {
        if (this.$refs.form.validate()) {
            this.save = true;


            let patchModel = jiff.diff(this.itemOriginal, this.item, false);

            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item))
                .then(
                    res => {
                        this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                        this.$emit("salvou");
                        this.Close();
                    },
                    err => {
                        this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning");
                    }
                ).finally(() => {
                    this.save = false;
                })
        }
    }
    Close() {
        this.dialog = false;
    }
}
