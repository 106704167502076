export class Contato {
    public nome: string = '';
    public ramal: string = '';
    public telefone: string = '';
    public celular: string = '';
    public email: string = '';

    constructor(model?: Contato) {

        if (!model)
            return;

        this.nome = model.nome;
        this.ramal = model.ramal;
        this.telefone = model.telefone;
        this.celular = model.celular;
        this.email = model.email;
    }
}