






















































































































































import { EnumPerfilUsuario, PageBase } from "@/core/models/shared";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class MasterPage extends PageBase {
    @Prop() icone!: string;
    @Prop() titulo!: string;
    @Prop() subTitulo!: string;

    drawer:boolean = false;
    active:boolean = false;
    dialogAlterarSenha: boolean = false;

    enumMaster = EnumPerfilUsuario.Master;
    enumGestor = EnumPerfilUsuario.Gestor;
    enumAluno = EnumPerfilUsuario.Aluno;
    enumConvidado = EnumPerfilUsuario.Convidado;

    cadastroMenuButtons: any[] = [
        {name:'Aluno',                  icon:'mdi-account',                             path:'/geral/aluno',                                disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor || this.app.role == this.enumAluno)},
        {name:'Turma',                  icon:'mdi-account-group',                       path:'/geral/turma',                                disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor || this.app.role == this.enumAluno)},
        {name:'Inscrição',              icon:'mdi-account-multiple-plus',               path:'/geral/inscricao',                            disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor || this.app.role == this.enumAluno || this.app.role == this.enumConvidado)},
        {name:'Profissão',              icon:'mdi-briefcase',                           path:'/geral/profissao',                            disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},
        {name:'Empresa',                icon:'mdi-domain',                              path:'/geral/empresa',                              disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},
    ];

    relatorioMenuButtons: any[] = [
        {name:'Cliente por Usuário',    icon:'mdi-account-multiple',                    path:'/relatorio/configuracao/clienteUsuario',      disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},
        {name:'Chamada',                icon:'mdi-text-box-check-outline',              path:'/relatorio/configuracao/chamada',             disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},        
        {name:'Crachá',                 icon:'mdi-badge-account-horizontal-outline',    path:'/relatorio/configuracao/cracha',              disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},
        {name:'Certificado',            icon:'mdi-certificate-outline',                 path:'/relatorio/configuracao/certificado',         disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},
        {name:'Aniversariantes',        icon:'mdi-cake',                                path:'/relatorio/configuracao/aniversariantes',     disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor)},
        {name:'Financeiro',             icon:'mdi-cash',                                path:'/relatorio/configuracao/financeiro',          disabled: false,            show: (this.app.role == this.enumMaster || this.app.role == this.enumGestor || this.app.role == this.enumConvidado)},
        {name:'Comissão',               icon:'mdi-account-cash',                        path:'/relatorio/configuracao/comissao',            disabled: false,            show: this.app.role == this.enumMaster},
        {name:'CashBack',               icon:'mdi-cash-refund',                         path:'/relatorio/configuracao/cashback',            disabled: false,            show: this.app.role == this.enumMaster},
    ];

    configuracaoMenuButtons: any[] = [
        {name:'Usuário',                icon:'mdi-account-tie',                         path:'/geral/usuario',                              disabled: false,            show: this.app.role == this.enumMaster},
        {name:'Forma Pagamento',        icon:'mdi-bank',                                path:'/geral/formaPagamento',                       disabled: false,            show: this.app.role == this.enumMaster},
        {name:'Local',                  icon:'mdi-map-marker',                          path:'/geral/local',                                disabled: false,            show: this.app.role == this.enumMaster},
        {name:'Curso',                  icon:'mdi-book-open-variant',                   path:'/geral/curso',                                disabled: false,            show: this.app.role == this.enumMaster},
        {name:'Parâmetros do Sistema',  icon:'mdi-cog',                                 path:'/parametros',                                 disabled: false,            show: this.app.role == this.enumMaster}
    ];

    mounted() {
        this.$vuetify.theme.dark = true;
    }

    GetAppVersion(){
        const pack = require('../../../package.json');
        return pack.version;
    }

    GetDiaSemana(dia: number, lingua: string){
        const diaSemanaString = new Date(Date.UTC(1, 0, dia)).toLocaleDateString(lingua, {weekday: 'long'});
        return diaSemanaString;
    }

    MudarTema(){
        alert('Deveria mudar o tema');
    };   
    
    AlterarSenha(){
        this.dialogAlterarSenha = true;
    };

    Logout() {
        localStorage.clear();
        this.$router.push({name: 'Login'});
    };
}
