import { http, httpHeader } from "../ApiConfig";
import { Service } from "../shared/Service";

export class LocalService extends Service {

  constructor() {
    super('local');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    })
  }

}