import { http, httpHeader } from "../ApiConfig";
import { Service } from "../shared/Service";

export class TurmaService extends Service {

  constructor() {
    super('turma');
  }

  public AutoComplete(q: string, include?: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: this.GetHeader(include).headers
    })
  }

  public GerarCracha(id: number, getCracha: boolean, idAlunos: string, idUsuario: string) {
    return http.get(`${this.GetNomeControle()}/${id}/GerarCracha`, {
      params: {
        getCracha,
        idAlunos,
        idUsuario
      },
      headers: httpHeader.headers
    })
  }

  public GerarCertificado(id: number, idAluno: string, idUsuario: string) {
    return http.get(`${this.GetNomeControle()}/${id}/GerarCertificado`, {
      params: {
        idAluno,
        idUsuario
      },
      headers: httpHeader.headers
    })
  }

  public Aprovar(id: number) {
    return http.post(`${this.GetNomeControle()}/${id}/Aprovar`);
  }

  public ListarAbertas(){
    return http.get(`${this.GetNomeControle()}?$OrderBy=dataInicio desc&$filter= ativo eq true and aprovado eq true`, {
      headers: this.GetHeader("Curso, Alunos, TipoHospedagem, Instrutores, Secretarios, Treinadores").headers
    });
  }

  public RelatorioComissao(id: number, gestorId: number){
    return http.get(`${this.GetNomeControle()}/${id}/RelatorioComissao`, {
      params: {
        gestorId
      }
    });
  }

}