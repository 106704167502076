






















































































































import { Vue,Prop, Watch, Component } from "vue-property-decorator";
import jiff from 'jiff';
import { Estado, Local, LocalAcomodacao } from "@/core/models/geral";
import { LocalService } from "@/core/services/geral";
import { EnderecoService } from "@/core/services/shared";

@Component
export default class CadastroLocal extends Vue {
    @Prop() private item!:Local;
    @Prop() private value!:string;

    save : boolean = false;
    itemOriginal!:Local;
    valid = true;
    dialog=false;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    mskCep = process.env.VUE_APP_MSK_CEP;
    locais: any[] =[];
    estadoId:number=0;
    estado:Estado = new Estado();
    estados: any[] = [];
    municipios: any[] = [];
    service = new LocalService();
    enderecoService = new EnderecoService();
    mskTelefone = process.env.VUE_APP_MSK_TELEFONE;

    tabActive: any = null;
    editaAcomodacao: boolean = false;
    localAcomodacao: LocalAcomodacao = new LocalAcomodacao();
    dialogAcomodacao: boolean = false;

    $refs!:{
        form:HTMLFormElement
    }

    headerAcomodacao: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: "Identificação", value: "identificacao" },
        { text: "Descrição", value: "observacao" },
    ];

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }
    @Watch('value')
    Value (){
        this.dialog = this.value ? true:false;

        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
        }
    }
    @Watch('dialog')
    Dialog(){
        if(!this.dialog){
            this.$emit("fechou");
        }
    }
    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }
    @Watch("item.endereco.municipioId")
    onMunicipioIdChanged(value: number) {
        if(this.item.endereco.municipioId == 0)
            return;
        this.estadoId = this.item.endereco.municipio.estadoId;
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }
    mounted() {
        this.enderecoService.ObterEstados()
        .then(
            res => {
                this.estados = res.data.items;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        );  
    }

    AbrirDialogAcomodacao(item?: LocalAcomodacao){
        if(item){
            this.editaAcomodacao = true;
            this.localAcomodacao =  item;
        }
        else{
            this.localAcomodacao = new LocalAcomodacao();
            this.editaAcomodacao = false;
            this.localAcomodacao.localId = this.item.id;            
        }

        this.dialogAcomodacao = true;
    }

    SalvarAcomodacao(){
        
        if(!this.editaAcomodacao){
            this.item.acomodacoes.push(this.localAcomodacao);
        }

        this.dialogAcomodacao = false;
    }

    ExcluirAcomodacao(item: LocalAcomodacao){
        this.$swal({
            title: 'Atenção!',
            text: 'Tem certeza que deseja excluir o registro atual?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {

                const index = this.item.acomodacoes.indexOf(item);
                this.item.acomodacoes.splice(index,1);

                return true;
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    Salvar (){
        if (this.$refs.form.validate()){
            this.save = true;

            let patchModel = jiff.diff(this.itemOriginal,this.item,false);

            (this.item.id > 0 ? this.service.Salvar(patchModel,this.item.id): this.service.Salvar(this.item))
            .then(
                res => {
                this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                this.$emit("salvou");
                this.Close();
                },
                err => {
                this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
                }
            ).finally(() => {
                this.save = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }

    CarregarMunicipio(estadoId: number){
        this.enderecoService.ObterMunicipios(estadoId)
        .then(
            res => {
                this.municipios = res.data.items;
            },
            err => this.$swal('Aviso', err.message, 'error')      
        );
    }

    ObterEndereco(cep:string){
        this.enderecoService.ObterPorCep(cep).then(
            res =>{
                const endereco = res.data;
                if(endereco){
                    this.item.endereco.logradouro = endereco.logadouro;
                    this.item.endereco.bairro = endereco.bairro;
                    this.item.endereco.municipioId = endereco.municipioId;
                }
            },
            err =>{
                this.$swal('Aviso',err.message,'error')
            }
        )
    }
}
