



























import { LocalAcomodacao } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CadastroAcomodacao extends PageBase{
    @Prop() private item!: LocalAcomodacao;
    @Prop() private value!: string;

    dialog: boolean = false;
    validDialog: boolean = false;

    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    $refs!: {
        form: HTMLFormElement
    }

    @Watch('item')
    Item(){

        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    mounted() {
        
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.$emit("salvou");
            this.dialog = false;
        }
    }

    Close() {
        this.dialog = false;
    }
}
