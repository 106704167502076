import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const verificaPerfil = (next: NavigationGuardNext<Vue>, perfilPermitido: string[]) =>{

  var sessionApp: any;

  if(localStorage.sessionApp){    

    sessionApp = JSON.parse(localStorage.sessionApp);

    if(perfilPermitido.find(a => a == sessionApp.dados.role) == undefined || perfilPermitido.find(a => a == sessionApp.dados.role) == null){
      next({ name: 'Home' });
    }
    else{
      next();
    }
  }
  else
  {
    next({ name: 'Login' });
  }  
}

const routes: Array<RouteConfig> = [
  { path: '*', redirect: '/home' },
  { path: '/login', name: "Login", component: () => import("../views/shared/Login.vue") },
  { path: "/resetSenha/:token", name: "resetSenha", component: () => import("../views/shared/Login.vue") },
  
  { path: '/home', name: "Home", component: () => import("../views/Home.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Aluno", "Convidado"]) },

  // CADASTRO
  { path: '/geral/aluno', name: "Aluno", component: () => import("../views/geral/aluno/Lista.vue"),                           beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Aluno"]) },
  { path: '/geral/aluno/detalhar/:id', name: "detalharAlunos", component: () => import("../views/geral/aluno/Detalhar.vue"),  beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Aluno"]) },
  { path: '/geral/turma', name: "Turma", component: () => import("../views/geral/turma/Lista.vue"),                           beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Aluno"]) },
  { path: '/geral/inscricao', name: "Inscricao", component: () => import("../views/geral/inscricao/Lista.vue"),               beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Aluno", "Convidado"]) },
  { path: '/geral/profissao', name: "Profissao", component: () => import("../views/geral/profissao/Lista.vue"),               beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  { path: '/geral/empresa', name: "Empresa", component: () => import("../views/geral/empresa/Lista.vue"),                     beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },

  // RELATORIOS
  { path: "/relatorio/configuracao/clienteUsuario", name: "confiuracaoClienteUsuario", component: () => import("../views/relatorio/clienteUsuario/Configuracao.vue"),     beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  { path: "/relatorio/impressao/clienteUsuario", name: "impressaoClienteUsuario", component: () => import("../views/relatorio/clienteUsuario/Relatorio.vue"),             beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  
  { path: "/relatorio/configuracao/aniversariantes", name: "configuracaoAniversariantes", component: () => import("../views/relatorio/aniversariantes/Configuracao.vue"), beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  { path: "/relatorio/impressao/aniversariantes", name: "impressaoAniversariantes", component: () => import("../views/relatorio/aniversariantes/Relatorio.vue"),          beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },

  { path: "/relatorio/configuracao/chamada", name: "configuracaoChamada", component: () => import("../views/relatorio/chamada/Configuracao.vue"),                         beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  { path: "/relatorio/impressao/chamada", name: "impressaoChamada", component: () => import("../views/relatorio/chamada/Relatorio.vue"),                                  beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  
  { path: "/relatorio/configuracao/cracha", name: "configuracaoCracha", component: () => import("../views/relatorio/cracha/Configuracao.vue"),                            beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  { path: "/relatorio/impressao/cracha", name: "impressaoCracha", component: () => import("../views/relatorio/cracha/Relatorio.vue"),                                     beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
    
  { path: "/relatorio/configuracao/certificado", name: "configuracaoCertificado", component: () => import("../views/relatorio/certificado/Configuracao.vue"),             beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
  { path: "/relatorio/impressao/certificado", name: "impressaoCertificado", component: () => import("../views/relatorio/certificado/Relatorio.vue"),                      beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor"]) },
    
  { path: "/relatorio/configuracao/financeiro", name: "configuracaoFinanceiro", component: () => import("../views/relatorio/financeiro/Configuracao.vue"),                beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Convidado"]) },
  { path: "/relatorio/impressao/financeiro", name: "impressaoFinanceiro", component: () => import("../views/relatorio/financeiro/Relatorio.vue"),                         beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Convidado"]) },
    
  { path: "/relatorio/impressao/financeiro2", name: "impressaoFinanceiro2", component: () => import("../views/relatorio/financeiro/Relatorio2.vue"),                      beforeEnter: (to, from, next) => verificaPerfil(next, ["Master", "Gestor", "Convidado"]) },
  { path: "/relatorio/configuracao/comissao", name: "configuracaoComissao", component: () => import("../views/relatorio/comissao/Configuracao.vue"),                      beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
    
  { path: "/relatorio/impressao/comissao", name: "impressaoComissao", component: () => import("../views/relatorio/comissao/Relatorio.vue"),                               beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  { path: "/relatorio/impressao/alunocashback", name: "impressaoCashBackAluno", component: () => import("../views/geral/aluno/RelatorioCashback.vue"),                    beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
    
  { path: "/relatorio/configuracao/cashback", name: "configuracaoCashBack", component: () => import("../views/relatorio/cashBack/Configuracao.vue"),                      beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  { path: "/relatorio/impressao/cashback", name: "impressaoCashBack", component: () => import("../views/relatorio/cashBack/Relatorio.vue"),                               beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  
  // CONFIGURACAO 
  { path: '/geral/usuario', name: "Usuario", component: () => import("../views/geral/usuario/Lista.vue"),                                                                 beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  { path: '/geral/formaPagamento', name: "Forma Pagamento", component: () => import("../views/geral/formaPagamento/Lista.vue"),                                           beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  { path: '/geral/local', name: "Local", component: () => import("../views/geral/local/Lista.vue"),                                                                       beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  { path: '/geral/curso', name: "Curso", component: () => import("../views/geral/curso/Lista.vue"),                                                                       beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
  { path: "/parametros", name: "parametroSistema", component: () => import("../views/parametros/Tela.vue"),                                                               beforeEnter: (to, from, next) => verificaPerfil(next, ["Master"]) },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
