import { Estado } from ".";
import { CursoEquipe } from "./CursoEquipe";

export class Curso implements Shared.IEntity {

    id: number = 0;
    nome: string = '';
    ativo: boolean = true;
    estadoId: number = 0;
    estado!: Estado;
    periodo: number = 0;
    cargaHoraria: number = 0;
    equipe: CursoEquipe[] = [];
    cracha: any = null;

    constructor(model?: Curso) {

        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.ativo = model.ativo;
        this.estadoId = model.estadoId;
        this.estado = model.estado;
        this.periodo = model.periodo;
        this.cargaHoraria = model.cargaHoraria;
        this.equipe = model.equipe;
        this.cracha = model.cracha;
    }
}