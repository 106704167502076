














































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Aluno, Empresa, Estado, Genero, PerfilUsuario, Profissao, Usuario,  } from "@/core/models/geral";
import { EnderecoService } from "@/core/services/shared/EnderecoService";
import { AlunoService, EmpresaService, GeneroService, ProfissaoService, UsuarioService } from "@/core/services/geral";
import { EnumPerfilUsuario, PageBase } from "@/core/models/shared";


@Component
export default class CadastroAluno extends PageBase {
  @Prop() private item!:Aluno;
  @Prop() private value!: string;

  itemOriginal!:Aluno;
  service = new AlunoService();
  valid = true;
  dialog = false;
  empresa = new Empresa();
  profissao = new Profissao();
  dialogEmpresa = false;
  dialogProfissao = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  mskCep = process.env.VUE_APP_MSK_CEP;
  mskCpf = process.env.VUE_APP_MSK_CPF;
  perfis: PerfilUsuario[] = [];
  foto: any = null;
  estadoId:number = 0;
  estado:Estado = new Estado();
  estados: any[] = [];
  municipios: any[] = [];
  enderecoService = new EnderecoService();

  onSearchIndicador: any = null
  isUsuarioLoading: boolean = false;
  usuarioService: UsuarioService = new UsuarioService();
  gestores: Usuario[] = [];
  indicadores: Usuario[] = [];

  onSearchGestor:any = null;

  profissaoId:number = 0;
  profissaoService = new ProfissaoService();
  isProfissaoLoading: boolean = false;
  onSearchProfissao:any = null;
  profissoes:Profissao[]=[];

  empresaId: number = 0;
  empresaService = new EmpresaService();
  isEmpresaLoading:boolean = false;
  onSearchEmpresa:any = null;
  empresas:Empresa []=[];

  generoId: number = 0;
  generoService = new GeneroService();
  isGeneroLoading:boolean=false;
  onSearchGenero: any = null;
  generos:Genero []=[];

  $refs!: {
    formUsuario: HTMLFormElement
  }

  masterEnum = EnumPerfilUsuario.Master;

  @Watch('item')
  Item() {
    if (this.$refs.formUsuario) {
      this.$refs.formUsuario.resetValidation();
    }
  }
  @Watch('value')
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);

      if (this.item.id > 0){
        this.indicadores.push(this.item.usuario.indicador);
        this.gestores.push(this.item.usuario.gestor);
      }

    }
  }
  @Watch('dialog')
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }
  @Watch("estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  }
  @Watch("item.endereco.municipioId")
  onMunicipioIdChanged(value: number) {
    
    if(this.item.endereco.municipioId == 0)
      return;

    if (this.item.endereco.municipio)
      this.estadoId = parseInt(this.item.endereco.municipioId.toString().substring(0, 2));
  }
  @Watch('onSearchIndicador')
  SearchIndicador (val:string){
    if(this.item.usuario.indicadorId) return;
    if(this.isUsuarioLoading) return;
    if (!val)return;
    this.isUsuarioLoading = true
    this.usuarioService.AutoComplete(val, true)
      .then(
        res => {
          this.indicadores = res.data;
        },
          err => this.$swal('Aviso',err.response.data, 'error')
    ).finally(() => {
      this.isUsuarioLoading = false;
    })
  }

  @Watch('onSearchGestor')
  SearchGestor(val:string){
    if(this.item.usuario.gestorId) return;
    if(this.isUsuarioLoading) return;
    if (!val)return;
    this.isUsuarioLoading = true
    this.usuarioService.AutoComplete(val)
      .then(
        res => {
          this.gestores = res.data;
        },
          err => this.$swal('Aviso',err.response.data, 'error')
    ).finally(() => {
      this.isUsuarioLoading = false;
    })
  }

  @Watch('onSearchEmpresa')
  SearchEmpresa(val:string){
    if(this.item.empresaId) return;
    if(this.isEmpresaLoading) return;
    if(!val)return;
    this.isEmpresaLoading = true;
    this.empresaService.AutoComplete(val)
      .then(
        res => {
          this.empresas = res.data;
        },
        err => this.$swal('Aviso',err.response.data,'error')
      ).finally(()=>
      this.isEmpresaLoading = false)
  }

  @Watch('onSearchProfissao')
  SearchProfissao(val:string){
    if(this.item.profissaoId) return;
    if(this.isProfissaoLoading) return;
    if(!val)return;
    this.isProfissaoLoading = true;
    this.profissaoService.AutoComplete(val)
      .then(
        res => {
          this.profissoes = res.data;
        },
        err => this.$swal('Aviso',err.response.data,'error')
      ).finally(()=>
      this.isProfissaoLoading = false)
  }

  @Watch('onSearchGenero')
  SearchGenero (val:string){
    if(this.item.generoId) return;
    if(this.isGeneroLoading) return;
    if (!val)return;
    this.isGeneroLoading = true
    this.generoService.AutoComplete(val)
      .then(
          res => {
            this.generos = res.data;
          },
          err => this.$swal('Aviso',err.response.data, 'error')
      ).finally(() => {
        this.isGeneroLoading = false;
    })
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }
  mounted() {

    // this.usuarioService.ListarTudo().then(
    //   res =>{
    //     this.usuarios = res.data.items;
    //   }
    // )

    this.enderecoService.ObterEstados().then(
      res => {
        this.estados = res.data.items;
      }
    )

    this.generoService.ListarTudo().then(
      res => {
        this.generos = res.data.items;
      },
      err => {
        this.$swal('Aviso',err.message,'error')
      }
    )
    this.empresaService.ListarTudo().then(
      res =>{
        this.empresas = res.data.items;
      },
      err =>{
        this.$swal('Aviso',err.message,'error')
      }
    )
    this.profissaoService.ListarTudo().then(
      res =>{
        this.profissoes = res.data.items;
      },
      err =>{
        this.$swal('Aviso',err.message,'error')
      }
    )
  }
  CarregarMunicipio(estadoId: number){
    this.enderecoService.ObterMunicipios(estadoId).then(
      res => {
        //let municipioId = this.item
        this.municipios = res.data.items;
      },
      err => this.$swal('Aviso', err.message, 'error')      
    );
  }
  ObterEndereco(cep:string){
    this.enderecoService.ObterPorCep(cep).then(
      res =>{
        const endereco = res.data;
        if(endereco){
          this.item.endereco.logradouro = endereco.logradouro;
          this.item.endereco.bairro = endereco.bairro;
          this.estadoId = endereco.estadoId;
          this.item.endereco.municipioId = endereco.municipioId;
        }
      },
      err =>{
        this.$swal('Aviso',err.message,'error')
      }
    )
  }

  async VerificaNomeCracha(): Promise<boolean>{

    if (this.item.nomeCracha.length <= 16){
      return true;
    }

    return await this.$swal({    
        title: 'Atenção, Verifique o nome do crachá!',
        text: 'Deseja prosseguir com o nome do crachá maior que 16 caracteres?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
    preConfirm: () => {
        return true;
    },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading()
    })
    .then((result) => {
        return result.value as boolean
    });

  }

  async Salvar() {

      if (!await this.VerificaNomeCracha()){
        return;
      }

      if (this.$refs.formUsuario.validate()) {

        this.item.usuario.cpf = this.item.cpf;
        this.item.usuario.login = this.item.email;
        this.item.usuario.complementar.nome = this.item.nome;
        this.item.usuario.nomeCracha = this.item.nomeCracha;

        let pacthModel = jiff.diff(this.itemOriginal, this.item, false);

        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
          res => {
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            if(this.itemOriginal.id == 0){
              this.$emit('inscrever', res.data.id, res.data);
            }
            this.$emit('salvou')  
            this.Close();
          },
          err => {
            this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
          }
        );
    }
  }

  Close() {
    this.indicadores = [];
    this.gestores = [];
    this.dialog = false;
  }
}

