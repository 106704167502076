






























































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { CursoEquipe, Estado, PerfilUsuario, Usuario } from "@/core/models/geral";
import { UsuarioService } from "@/core/services/geral/UsuarioService";
import { PerfilUsuarioService } from "@/core/services/geral/PerfilUsuarioService";
import { Endereco, EnumPerfilUsuario, PageBase } from "@/core/models/shared";
import { ArquivoService, EnderecoService } from "@/core/services/shared";


@Component
export default class CadastroUsuario extends PageBase {
  @Prop() private item!:Usuario;
  @Prop() private value!: string;

  save : boolean = false;
  itemOriginal!:Usuario;
  service = new UsuarioService();
  valid = true;
  validEquipe = true;
  dialog = false;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  perfis: PerfilUsuario[] = [];
  foto: any = null;
  assinatura: any = null;
  tabActive: any = null;
  usuarioId:number = 0;
  percentual: number = 0;
  usuarios: Usuario[] = [];
  isEquipeLoading: boolean =false;

  enderecoService = new EnderecoService();
  estadoId:number = 0;
  estado:Estado =new Estado();
  estados:any[] = [];
  municipios:any[] = [];

  mskCep = process.env.VUE_APP_MSK_CEP;
  mskCpf = process.env.VUE_APP_MSK_CPF;

  $refs!: {
    formUsuario: HTMLFormElement
    formEquipe: HTMLFormElement
  }

  headerEquipe: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: "Equipe", value: "complementar.nome" },
    { text: "Percentual(%)",value:"percentualComissao"}
  ];

  enumMaster = EnumPerfilUsuario.Master;

  @Watch('item')
  Item() {
    if (this.$refs.formUsuario) {
      this.$refs.formUsuario.resetValidation();
    }
    if (this.$refs.formEquipe) {
      this.$refs.formEquipe.resetValidation();
    }
    if(this.item.id > 0 ){
      this.service.GetAssinatura(this.item.id).then(
        res => {
          this.itemOriginal.assinatura = res.data;
          this.item.assinatura = res.data
        }
      )
    }
  }

  @Watch('value')
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
    }
  }

  @Watch('dialog')
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  @Watch("estadoId")
  onEstadoIdChanged(value: number) {
    this.CarregarMunicipio(value);
  }

  @Watch("item.endereco.municipioId")
  onMunicipioIdChanged(value: number) {
    
    if(this.item.endereco.municipioId == 0)
        return;

    if (this.item.endereco.municipio)
      this.estadoId = parseInt(this.item.endereco.municipioId.toString().substring(0, 2));
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  AdicionarUsuarioEquipe(verificar: boolean = false){
    if(this.$refs.formEquipe.validate()){

      if(verificar && this.item.equipe.find(x => x.id == this.usuarioId)){
        this.$swal('Aviso',"Usuário já se encontra adicionado!",'warning')
      }
      else{
        this.service.VincularEquipe(this.item.id, this.usuarioId, this.percentual).then(
          res =>{
            var usuario = this.usuarios.find(x => x.id == this.usuarioId);
            usuario!.percentualComissao = this.percentual;
            this.item.equipe.push(usuario!);
          },
          err =>{
            this.$swal('Aviso',err.response.data,'error')
          }
        ).finally(() => {
          this.percentual = 0;
          this.usuarioId = 0;
          this.$refs.formEquipe.resetValidation();
        })
      }
    }
  }

  ExcluirUsuarioEquipe(usuario:Usuario){
    this.service.DesvincularEquipe(usuario.id).then(
      res =>{
        const index = this.item.equipe.indexOf(usuario);
        this.item.equipe.splice(index,1);
      },
      err =>{
        this.$swal('Aviso',err.response.data,'error')
      }
    )
  }

  ExcluirEquipe(usuario:Usuario){
    this.$swal({
    title: 'Atenção!',
    text: 'Tem certeza que deseja excluir o registro atual?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    showCloseButton: true,
    showLoaderOnConfirm: true,
    preConfirm: () => {
        
        const index = this.item.equipe.indexOf(usuario);
        this.item.equipe.splice(index,1);

        return true;
        },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading()
    })
  }

  CarregarMunicipio(estadoId: number){
      this.enderecoService.ObterMunicipios(estadoId)
      .then(
          res => {
              this.municipios = res.data.items;
          },
          err => this.$swal('Aviso', err.message, 'error')      
      );
  }

  ObterEndereco(cep:string){
    this.enderecoService.ObterPorCep(cep).then(
        res =>{
            const endereco = res.data;
            if(endereco){
                this.item.endereco.logradouro = endereco.logradouro;
                this.item.endereco.bairro = endereco.bairro;
                this.estadoId = endereco.estadoId;
                this.item.endereco.municipioId = endereco.municipioId;
            }
        },
        err =>{
            this.$swal('Aviso',err.message,'error')
        }
    ).finally(() => {
        this.save = false;
    })
  }

  async LoadAssinatura(){
    if (!this.assinatura)
      return;

    const arquivoService = new ArquivoService();
    const dados = await arquivoService.Ler(this.assinatura);
    this.item.assinatura = dados.replace(/^[^,]*,/, "");
  }

  RemoveAssinatura(){
    this.item.assinatura = null;
    this.assinatura = null;
  }

  mounted() {
    const perfilservice = new PerfilUsuarioService();
    perfilservice.ListarTudo().then(
      res => {
        this.perfis = res.data.items;
      },
      err => {
        this.$swal('Aviso', err.message, 'error')}
    );
    this.service.ListarGestores().then(
      res =>{
        this.usuarios = res.data.items;
      },
      err =>{
        this.$swal('Aviso',err.message,'error')
      }
    )
    this.enderecoService.ObterEstados()
    .then(
      res=>{
        this.estados = res.data.items;
      },err=>{
        this.$swal('Aviso',err.message,'error')
      }
    )  
  }

  async VerificaNomeCracha(): Promise<boolean>{

    if (this.item.nomeCracha.length <= 16){
      return true;
    }

    return await this.$swal({    
        title: 'Atenção, Verifique o nome do crachá!',
        text: 'Deseja prosseguir com o nome do crachá maior que 16 caracteres?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
    preConfirm: () => {
        return true;
    },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading()
    })
    .then((result) => {
        return result.value as boolean
    });

  }

  async Salvar() {

    if (!await this.VerificaNomeCracha()){
      return;
    }

    if (this.$refs.formUsuario.validate()) {
      
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);

      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          if(this.item.equipe.length > 0){
            this.item.equipe.forEach(x => {
              this.usuarioId = x.id;
              this.percentual = x.percentualComissao;
              this.service.VincularEquipe(this.item.id, this.usuarioId, this.percentual).then(
                res => {
                  this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                  this.$emit("salvou");
                  this.Close();
                },
                err => {
                  this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
                }
              );
            })   
          }
          else{
            this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
            this.$emit("salvou");
            this.Close();
          }
        },
        err => {
          this.$swal("Aviso", err.response.data, err.response.status >= 500 ? "error" : "warning" );
        }
      );      
    }
  }

  Close() {
    this.dialog = false;
    this.usuarioId = 0;
    this.percentual = 0;
  }
}
