




import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TextSearch extends Vue {
    @Prop() private search!: string;
    
    localSearch: string = '';

    Mudou(){
        this.$emit('update:search', this.localSearch);
        this.$emit("mudou");
    }
}

