import Vue from "vue";

import AlterarSenha from "./shared/AlterarSenha.vue"
import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import RelatorioPage from "./shared/RelatorioPage.vue";

import CardCracha from "./shared/CardCracha.vue"

import CadastroAluno from "./geral/aluno/Cadastro.vue";
import CadastroCurso from "./geral/curso/Cadastro.vue";
import CadastroFormaPagamento from "./geral/formaPagamento/Cadastro.vue";
import CadastroLocal from "./geral/local/Cadastro.vue";
import CadastroLocalAcomodacao from "./geral/local/CadastroAcomodacao.vue";
import CadastroTurma from "./geral/turma/Cadastro.vue";
import CadastroUsuario from "./geral/usuario/Cadastro.vue";
import CadastroEmpresa from "./geral/empresa/Cadastro.vue";
import CadastroProfissao from "./geral/profissao/Cadastro.vue";
import CadastroInscricao from "./geral/inscricao/Cadastro.vue";

Vue.component("alterar-senha", AlterarSenha);
Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("relatorio-page", RelatorioPage);

Vue.component("card-cracha", CardCracha);

// Cadastros
Vue.component("cadastro-aluno", CadastroAluno);
Vue.component("cadastro-curso", CadastroCurso);
Vue.component("cadastro-formaPagamento", CadastroFormaPagamento);
Vue.component("cadastro-local", CadastroLocal);
Vue.component("cadastro-local-acomodacao", CadastroLocalAcomodacao);
Vue.component("cadastro-turma", CadastroTurma);
Vue.component("cadastro-usuario", CadastroUsuario);
Vue.component("cadastro-empresa", CadastroEmpresa);
Vue.component("cadastro-profissao", CadastroProfissao);
Vue.component("cadastro-inscricao", CadastroInscricao);