import { http } from "../ApiConfig";
import { Service } from "../shared/Service";

export class CashBackService extends Service {

  constructor() {
    super('cashback');
  }

  public CashBackDisponivel(alunoId: number) {
    return http.get(`${this.GetNomeControle()}/Aluno/${alunoId}`);
  }

  public RelatorioAluno(alunoId: number) {
    return http.get(`${this.GetNomeControle()}/RelatorioAluno/${alunoId}`);
  }

  public Relatorio(turmaId: number, alunoId: number) {
    var turma = turmaId > 0 ? turmaId : null;
    var aluno = alunoId > 0 ? alunoId : null;

    return http.get(`${this.GetNomeControle()}/Relatorio`, {
      params: {
        turmaId: turma,
        alunoId: aluno
      }
    });
  }

  public GerarCashBack(turmaId: number) {
    return http.post(`${this.GetNomeControle()}/Gerar/${turmaId}`);
  }

  public Utilizar(ids: number[], alunoTurmaId: number){
    return http.post(`${this.GetNomeControle()}/Utilizar/${alunoTurmaId}`,
      ids
    );
  }

}