import { Local } from ".";

export class LocalAcomodacao implements Shared.IEntity{

    id: number = 0;
    localId: number = 0;
    local!: Local;
    identificacao: string = '';
    observacao: string = '';
    ativo: boolean = true;

    constructor(model?: LocalAcomodacao){

        if(!model)
            return;

        this.id = model.id;
        this.localId = model.localId;
        this.local = model.local;
        this.identificacao = model.identificacao;
        this.observacao = model.observacao;
        this.ativo = model.ativo;
    }
}    