import { AlunoTurma, Curso, Local, TurmaAcomodacao, TurmaInstrutor } from ".";
import { Aprovacao } from "../shared/Aprovacao";
import { Solicitacao } from "../shared/Solicitacao";
import { TipoHospedagem } from "./TipoHospedagem";
import { TurmaSecretario } from "./TurmaSecretario";
import { TurmaTreinador } from "./TurmaTreinador";

export class Turma implements Shared.IEntity {

    id: number = 0;
    codigo: string = '';
    vagas: number = 0;
    localId: number = 0;
    local!: Local;
    localValor: number = 0;
    tipoHospedagemId: number = 0;
    tipoHospedagem!: TipoHospedagem;
    acomodacoes: TurmaAcomodacao[] = [];
    cursoId: number = 0;
    curso!: Curso;
    observacao: string = '';
    dataInicio: string = '';
    dataFim: string = '';
    alunos: AlunoTurma[] = [];
    instrutores: TurmaInstrutor[] = [];
    secretarios: TurmaSecretario[] = [];
    treinadores: TurmaTreinador[] = [];
    valorAVista: number = 0;
    valorParceladoAte6Vezes: number = 0;
    valorParceladoAte12Vezes: number = 0;
    valorParceladoAte18Vezes: number = 0;
    ativo: boolean = true;
    exibirSite: boolean = false;
    isValidada: boolean = false;
    valorCashback: number = 0;
    aprovado: boolean = false;
    aprovacao: Aprovacao = new Aprovacao();
    solicitacao: Solicitacao = new Solicitacao();
    cursoCodigo: string = '';

    constructor(model?: Turma) {

        if (!model)
            return;

        this.id = model.id;
        this.codigo = model.codigo;
        this.vagas = model.vagas;
        this.localValor = model.localValor;
        this.localId = model.localId;
        this.local = model.local;
        this.tipoHospedagemId = model.tipoHospedagemId;
        this.tipoHospedagem = model.tipoHospedagem;
        this.acomodacoes = model.acomodacoes;
        this.cursoId = model.cursoId;
        this.curso = model.curso;
        this.observacao = model.observacao;
        this.dataInicio = model.dataInicio ? model.dataInicio.toDateYYYYMMDD() : '';
        this.dataFim = model.dataFim ? model.dataFim.toDateYYYYMMDD() : '';
        this.alunos = model.alunos;
        this.instrutores = model.instrutores;
        this.secretarios = model.secretarios;
        this.treinadores = model.treinadores;
        this.valorAVista = model.valorAVista;
        this.valorParceladoAte6Vezes = model.valorParceladoAte6Vezes;
        this.valorParceladoAte12Vezes = model.valorParceladoAte12Vezes;
        this.valorParceladoAte18Vezes = model.valorParceladoAte18Vezes;
        this.ativo = model.ativo;
        this.exibirSite = model.exibirSite;
        this.isValidada = model.isValidada;
        this.valorCashback = model.valorCashback;
        this.aprovado = model.aprovado;
        this.solicitacao = model.solicitacao;
        this.aprovacao = model.aprovacao;
        this.cursoCodigo = model.cursoCodigo;
    }
}