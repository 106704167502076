import { Usuario } from "../geral/Usuario";

export class Solicitacao {

    dataHora?: string;
    justificativa: string = '';
    solicitanteId?: number;
    solicitante!: Usuario;

    constructor(model?: Solicitacao) {

        if (!model)
            return;

        this.dataHora = model.dataHora ? model.dataHora.toDateYYYYMMDD() : '';
        this.justificativa = model.justificativa;
        this.solicitanteId = model.solicitanteId;
        this.solicitante = model.solicitante;
    }
}